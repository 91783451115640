<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M9.00033 17.3327C7.84755 17.3327 6.76421 17.1139 5.75033 16.6764C4.73644 16.2389 3.85449 15.6452 3.10449 14.8952C2.35449 14.1452 1.76074 13.2632 1.32324 12.2493C0.885742 11.2355 0.666992 10.1521 0.666992 8.99935C0.666992 7.84657 0.885742 6.76324 1.32324 5.74935C1.76074 4.73546 2.35449 3.85352 3.10449 3.10352C3.85449 2.35352 4.73644 1.75977 5.75033 1.32227C6.76421 0.884766 7.84755 0.666016 9.00033 0.666016C10.1531 0.666016 11.2364 0.884766 12.2503 1.32227C13.2642 1.75977 14.1462 2.35352 14.8962 3.10352C15.6462 3.85352 16.2399 4.73546 16.6774 5.74935C17.1149 6.76324 17.3337 7.84657 17.3337 8.99935C17.3337 10.1521 17.1149 11.2355 16.6774 12.2493C16.2399 13.2632 15.6462 14.1452 14.8962 14.8952C14.1462 15.6452 13.2642 16.2389 12.2503 16.6764C11.2364 17.1139 10.1531 17.3327 9.00033 17.3327ZM9.83366 4.83268V9.83268H8.16699V4.83268H9.83366ZM9.59408 12.9264C9.43435 13.0862 9.23644 13.166 9.00033 13.166C8.76421 13.166 8.5663 13.0862 8.40658 12.9264C8.24685 12.7667 8.16699 12.5688 8.16699 12.3327C8.16699 12.0966 8.24685 11.8987 8.40658 11.7389C8.5663 11.5792 8.76421 11.4993 9.00033 11.4993C9.23644 11.4993 9.43435 11.5792 9.59408 11.7389C9.7538 11.8987 9.83366 12.0966 9.83366 12.3327C9.83366 12.5688 9.7538 12.7667 9.59408 12.9264ZM13.7295 13.7285C12.4378 15.0202 10.8614 15.666 9.00033 15.666C7.13921 15.666 5.56283 15.0202 4.27116 13.7285C2.97949 12.4368 2.33366 10.8605 2.33366 8.99935C2.33366 7.13824 2.97949 5.56185 4.27116 4.27018C5.56283 2.97852 7.13921 2.33268 9.00033 2.33268C10.8614 2.33268 12.4378 2.97852 13.7295 4.27018C15.0212 5.56185 15.667 7.13824 15.667 8.99935C15.667 10.8605 15.0212 12.4368 13.7295 13.7285Z"
      fill="#FFC107" />
  </svg>



</template>

<script>
export default {

}
</script>


