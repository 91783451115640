<template>
  <div>
    <input min="0" type="number" :value="value" @input="updateValue" :disabled="disabled">

  </div>
</template>

<script>
import { checkForNullEmptyOrNegative } from '@/utils/functions.js'

export default {
  data() {
    return {
      vk: ''
    }
  },
  props:  {
    value: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateValue(event) {
      const value = event.target.value;
      const checkedValue = checkForNullEmptyOrNegative(value);
      this.$emit('input', checkedValue);
    }
  }

}
</script>

<style lang="scss" scoped></style>