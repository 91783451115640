<template>

  <div class="inventory">
    <WineDetailOverlay ref="wineDetailOverlay" v-model="selectedWine" :detailsClass="detailsClass"
      :closePressed="closeWineDetails" :inventoryMode="true" />

    <div style="display:flex;  flex: 1;
        overflow: hidden; flex-direction: row; min-width: 100%;  ">
      <b-table style="display: flex;flex: 1; max-height: calc(100vh - 290px);" v-model="currentItems"
        select-mode="single" id="wines" selectable ref="wines" :items="filteredWines" :fields="fields" sticky-header
        @row-selected="(item) => showInventorySidepanel(item)" @context-changed="handleRefreshed"
        :sort-compare="mySortCompare" :per-page="perPage" :current-page="currentPage">
        <template #cell(venueWine)="data">
          <b-row>
            {{ data.value.wine.winery.title }}
          </b-row>
          <b-row class="font-16 color-white">
            {{ data.value.title }}
          </b-row>
        </template>
        <template #cell(venueWine.bottleSize)="data">

          <b-row>
            {{ data.value.amount }} l
          </b-row>
        </template>
        <template #cell(venueWine.wine.wineType)="data">

          <b-row>
            {{ $t('wineTypes.' + data.value.localeKey) }}
          </b-row>
        </template>

        <template class="inventoryVenueWineState" #cell(venueWine.inventoryVenueWineState)="data">
          <div v-if="getInventoryState(data.item) === 'valid'">
            <CheckedIcon />
          </div>
          <div v-if="getInventoryState(data.item) === 'invalid'">
            <InvalidIcon />
          </div>
          <div v-if="getInventoryState(data.item) === 'duplicate'">
            <DuplicateIconChecked />
          </div>

        </template>
        <!--
        <template #table-colgroup="scope">
          <col v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'venueWine.bottleSize' ? '30px' : '180px' }"
            >
        </template>
        -->

      </b-table>

      <InventorySidepanel :tableLength="currentItems.length" :selectedRowIndex="selectedRowIndex"
        :showSidepanel="showSidepanel" @previousRow="previousRow" @nextRow="nextRow"
        :selectedInventoryVenueWine="selectedInventoryVenueWine" ref="sidepanel" :currentPage="currentPage" />
    </div>
    <b-pagination class="pagination" @change="paginationClick" v-model="currentPage" :total-rows="wines.length" :per-page="perPage"
      aria-controls="my-table" hide-ellipsis prev-text="" next-text="" first-text="" last-text="" limit="4"
      align="center"></b-pagination>
  </div>

</template>

<script>
import InventorySidepanel from './InventorySidepanel.vue';
import CheckedIcon from '../icons/CheckedIcon.vue';
import InvalidIcon from '../icons/InvalidIcon.vue';
import WineDetailOverlay from '../WineDetailOverlay.vue';
import { mapGetters } from 'vuex';
import DuplicateIconChecked from '../icons/DuplicateIconChecked.vue';

export default {
  name: 'InventoryContent',
  components: {
    InventorySidepanel,
    CheckedIcon,
    InvalidIcon,
    WineDetailOverlay,
    DuplicateIconChecked
  },
  data() {
    return {
      selectedInventoryVenueWine: null,
      showSidepanel: false,
      searchFilter: '',
      currentItems: [],
      selectedWine: {},
      detailsClass: '',
      selectedRowIndex: null,
      currentPage: 1,
      perPage: 200,
      selectRowAfterRefresh: false,
      clickFromPreviousRow: false
    }
  },
  props: {
    wines: {
      type: Array,
    },
    inventoryState: {
      type: String,
    }
  },
  watch: {
    selectedRowIndex(rowIndex) {
      this.$emit('changedRowIndex', rowIndex)
    },

  },
  methods: {
    amountRows() {
      console.log('this.wines.length :>> ', this.wines.length);
      return this.wines.length
    },
    scrollToRow(index) {
      const table = this.$refs.wines.$el.getBoundingClientRect();
      const top = table.top
      const bottom = table.bottom
      const tbody = this.$refs.wines.$el.querySelector('tbody');
      const row = tbody.querySelectorAll('tr')[index];
      const rowRect = row.getBoundingClientRect();
      if (row) {
        const isInViewport = (
          rowRect.top >= top &&
          rowRect.bottom <= bottom
        );
        if (!isInViewport) {
          row.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },
    getInventoryState(item) {

      if (item.actualAmount == item.targetAmount && item.inventoryVenueWineState === 'checked' && item.shelf !== '' && item.shelf !== null) {
        return 'valid'
      } else if (item.duplicate === true) {
        return 'duplicate'
      } else if (item.inventoryVenueWineState == 'none') {
        return 'unchecked'
      } else {
        return 'invalid'
      }
    },
    openSidepanelFromButton() {
      const index = this.currentItems.findIndex(inventory => {
        return inventory.inventoryVenueWineState == 'none'
      });
      if (index === -1) {
        // open first Element in List
        this.selectedRowIndex = 0
        this.selectedInventoryVenueWine = this.currentItems[0];
      } else {
        // open first unchecked Wine
        this.selectedRowIndex = index
        this.selectedInventoryVenueWine = this.currentItems[index];
      }
      this.$emit('buttonTextInventoryOff')
      this.$refs.wines.selectRow(this.selectedRowIndex)
      this.scrollToRow(this.selectedRowIndex)

      
    },
    showInventorySidepanel(item) {
      if (this.inventoryState === 'finished') {
        return
      }
      if (item.length === 0 && this.clickFromPreviousRow) {
        this.showSidepanel = true
        this.$emit('buttonTextInventoryOff')
        this.selectedInventoryVenueWine = this.currentItems[this.selectedRowIndex];
        this.clickFromPreviousRow = false
        return;
      }
      
      if (item.length === 0) { /* click on already selected row */
/*         this.closeSelectedRow()
 */        return;
      }
      const index = this.currentItems.findIndex(inventory => inventory.id === item[0].id);
      if (this.selectedRowIndex === index) {
        this.selectedRowIndex = null
      }
      this.selectedRowIndex = index
      this.selectedInventoryVenueWine = this.currentItems[index];
      if (!this.isInventoryOnly) {
        this.showRegularSidepanel(item)
        return
      }
      this.$nextTick(() => {
        this.showSidepanel = true;
      })
      this.$emit('buttonTextInventoryOff')
    },
    closeSelectedRow() {
      console.log('closeSelectedRow');
      this.selectedRowIndex = null
      this.showSidepanel = false;
      this.$emit('buttonTextInventoryOn')
    },
    closeSidepanel() {
      console.log('close');
      this.showSidepanel = false;
      this.selectedInventoryVenueWine = null;
      this.selectedRowIndex = null
      this.$refs.wines.clearSelected()
      this.$emit('buttonTextInventoryOn')
    },
    showRegularSidepanel(item) {
      const index = this.filteredWines.findIndex(inventory => inventory.id === item[0].id);
      this.selectedRowIndex = index
      this.selectedInventoryVenueWine = this.filteredWines[index];
      this.showWineDetails(this.selectedInventoryVenueWine.venueWine)
    },
    showWineDetails(venuewine) {
      this.selectedWine = this.mapVenueWineForSidepanel(venuewine)
      this.detailsClass = "active"
    },
    mapVenueWineForSidepanel(venuewine) {
      /* can be ditched when WineDetailOverlay is updated with the mapped API Data */
      const venueWineForSidepanel = {
        id: venuewine.id,
        attributes: {
          ...venuewine,
          bottleSize: {
            data: {
              attributes: {
                ...venuewine.bottleSize
              },
              id: venuewine.bottleSize.id

            }
          },
          wine: {
            data: {
              id: venuewine.wine.id,
              attributes: {
                ...venuewine.wine,
                land: {
                  data: {
                    attributes: {
                      ...venuewine.wine.land
                    },
                    id: venuewine.wine.land.id
                  }
                },
                region: {
                  data: {
                    attributes: {
                      ...venuewine.wine.region
                    },
                    id: venuewine.wine.region.id
                  }
                },
                grapeVariety: {
                  data: {
                    attributes: {
                      ...venuewine.wine.grapeVariety
                    },
                    id: venuewine.wine.grapeVariety.id
                  }
                },
                wineType: {
                  data: {
                    attributes: {
                      ...venuewine.wine.wineType
                    },
                    id: venuewine.wine.wineType.id
                  }
                },
                winery: {
                  data: {
                    attributes: {
                      ...venuewine.wine.winery
                    },
                    id: venuewine.wine.winery.id
                  }
                }
              }
            }
          },
          wineId: venuewine.wine.id,
        }
      }
      return venueWineForSidepanel
    },
    closeWineDetails() {
      this.detailsClass = ""
      this.selectedWine = {}
    },
    paginationClick() {
      this.selectRowAfterRefresh = true
    },
    nextRow(inventoryVenueWine, newIndex) {

      if (this.inventoryState === 'uploaded') {
        this.$emit('changeStateBackToOpen')
      }
      this.$emit('nextRow', inventoryVenueWine, this.selectedRowIndex)
      if (newIndex !== undefined) {
        this.selectedRowIndex = newIndex;
        if (this.currentPage < this.wines.length / this.perPage) {
          this.currentPage += 1
        } else {
          this.currentPage = 1
        }
        this.$refs.wines.selectRow(this.selectedRowIndex)
        this.scrollToRow(this.selectedRowIndex)
      } else {
        this.selectedRowIndex++;
        this.$refs.wines.selectRow(this.selectedRowIndex)
        this.scrollToRow(this.selectedRowIndex)
      }
    },
    handleRefreshed() {
      if (this.selectRowAfterRefresh == true) {
        this.selectRowAfterRefresh = false
        this.$nextTick(() => {
          this.$refs.wines.selectRow(0)
          this.scrollToRow(0)
          console.log('this.selectedRowIndex :>> ', this.selectedRowIndex);
        })
      }
    },
    previousRow() {
      if (this.currentPage < this.wines.length / this.perPage && this.currentPage > 1 && this.selectedRowIndex === 0) {
        this.currentPage -= 1
        this.clickFromPreviousRow = true
        this.selectedRowIndex = this.perPage - 1
        this.$nextTick(() => {
          this.$refs.wines.selectRow(this.selectedRowIndex)
          this.scrollToRow(this.selectedRowIndex)


        })
      } else {
        this.selectedRowIndex--;
        this.$refs.wines.selectRow(this.selectedRowIndex)
        this.scrollToRow(this.selectedRowIndex)

      }

    },
    searchWines(wine) {
      if (!this.searchFilter) {
        return true;
      }
      const searchLower = this.searchFilter.toLowerCase();
      return (
        wine.venueWine.wine.winery.title?.toLowerCase().includes(searchLower) ||
        wine.venueWine.title?.toLowerCase().includes(searchLower) ||
        (wine.shelf?.toLowerCase().includes(searchLower)) ||
        wine.venueWine.bottleSize.amount?.toString().includes(this.searchFilter) ||
        wine.venueWine.year?.toString().includes(this.searchFilter) ||
        wine.venueWine.wine.wineType.title?.toLowerCase().includes(searchLower) ||
        wine.venueWine.wine.grapeVariety.title?.toLowerCase().includes(searchLower)
      );
    },
    mySortCompare(a, b, key) {
      if (key === 'venueWine') {
        return a[key].wine.winery.title.localeCompare(b[key].wine.winery.title)
      } else {
        // Let b-table handle sorting other fields (other than `venueWine` field)
        return false
      }
    }




  },
  mounted() {
    this.$root.$on('changedSearchFilter', (filter) => {
      this.searchFilter = filter
    })

  },


  beforeDestroy() {
  },
  created() {

  },
  computed: {
    filteredWines() {
      return this.wines.filter(wine => this.searchWines(wine));
    },
    ...mapGetters(['isInventoryOnly']),
    fields() {
      const fields = [
        {
          key: 'venueWine',
          label: this.$t('general.wine'),
          sortable: true,
          sortKey: 'value.wine.winery.title'
        },
        {
          key: 'venueWine.bottleSize',
          label: this.$t('general.size'),
          sortable: true
        },
        {
          key: 'venueWine.year',
          label: this.$t('general.year'),
          sortable: true
        },
        {
          key: 'shelf',
          label: this.$t('general.shelf'),
          sortable: true
        },
        {
          key: 'venueWine.inventoryVenueWineState',
          label: '',
          sortable: true
        },
      ]
      if (!this.showSidepanel) {
        let insertPosition = fields.length - 2;
        fields.splice(insertPosition, 0,
          {
            key: 'venueWine.wine.wineType',
            label: this.$t('general.color'),
            sortable: true
          },
          {
            key: 'venueWine.wine.grapeVariety.title',
            label: this.$t('general.grapeVariety'),
            sortable: true
          },
        )
      }
      if (!this.isInventoryOnly) {
        let insertPosition = fields.length - 2;
        fields.splice(insertPosition, 0,
          {
            key: 'actualAmount',
            label: this.$t('inventory.actual'),
          },
          {
            key: 'targetAmount',
            label: this.$t('inventory.target'),
          }
        );
      }
      return fields
    }
  },

}
</script>

<style>
.inventory table tr.b-table-row-selected.table-active {
  background-color: var(--color-gray-250) !important;
}
</style>