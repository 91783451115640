<template>
  <div class="sidepanel" :class="{ 'active': showSidepanel }">
    <div v-if="selectedInventoryVenueWine"
      style="display: flex; justify-content: space-between; flex-direction: column; height: 100%;">
      <div class="block-one">


        <b-row>
          <span class="font-14 color-150 w-700">{{ selectedInventoryVenueWine.venueWine.wine.winery.title }}</span>
        </b-row>
        <b-row>
          <span class="font-24 w-400 color-100 mb-xs">{{ selectedInventoryVenueWine.venueWine.title }}</span>
        </b-row>
        <b-row class="mb-l">
          <b-col class="tags font-12 w-500 color-150">
            <span class="box">{{ selectedInventoryVenueWine.venueWine.bottleSize.title }}</span>
            <span class="box">{{ selectedInventoryVenueWine.venueWine.year }}</span>
            <span class="box">{{ selectedInventoryVenueWine.venueWine.wine.wineType.title }}</span>
            <span class="box">{{ selectedInventoryVenueWine.venueWine.wine.grapeVariety.title }}</span>
          </b-col>
        </b-row>
        <b-row class="color-100 font-14 w-700 mb-xs">
          <b-col class="rowSpaceBetween">
            {{ $t('general.ekNet') }}
            <TooltipButton v-if="!selectedInventoryVenueWine.canUpdatePurchasePrice"
              :tooltipText="$t('inventory.ekNetTooltip')" />
          </b-col>
          <b-col>
            {{ $t('general.vkGross') }}
          </b-col>
          <b-col>
            {{ $t('general.shelf') }}
          </b-col>
        </b-row>
        <b-row class="mb-m color-150">
          <b-col>

            <EditSellingPrice :disabled="!selectedInventoryVenueWine.canUpdatePurchasePrice" v-model="ek" />
          </b-col>
          <b-col>
            <EditSellingPrice v-model="vk" />
          </b-col>
          <b-col>
            <input v-model="shelf">
          </b-col>
        </b-row>
      </div>
      <div class="block-two">
        <div class="calculate align-center mb-m">
          <b-row class="mb-m color-100">
            <span class="align-center font-40"
              :class="{ 'amount-not-set': (selectedInventoryVenueWine.actualAmount === null && !actualAmountChanged) || (actualAmount === null && actualAmountChanged) }">{{
                actualAmount == null ? 0 : actualAmount }} </span>
            <span class="align-center font-14 w-700">{{ $t('inventory.stock') }}</span>


          </b-row>
          <b-row class="number-pad font-18">
            <div class="number-row" v-for="row in numberPad" :key="row.join('')">
              <span class="number" v-for="n in row" :key="n">
                <b-button class="number" @click="addNumber(n)">{{ n }}</b-button>
              </span>
            </div>
            <div class="bottom-row">
              <div class="duplicate align-center cursor-pointer">
                <div class="cursor-pointer" @click="markAsDuplicate">
                  <DuplicateIconChecked v-if="duplicate === true" width="24" height="24" />
                  <DuplicateIcon v-else width="24" height="24" fill="rgba(73, 80, 87, 1)" />
                </div>

              </div>
              <span class="number">
                <b-button class="number" @click="addNumber(0)">0</b-button>
              </span>
              <div @click="deleteNumber()" class="delete-btn align-center"><img
                  src="../../assets/img/arrow-v2-number-pad.svg" /></div>
            </div>
          </b-row>

        </div>
      </div>
      <div>
        <b-row class="buttons">
          <b-col>

            <UpButton :onClick="previousRow" :buttonText="$t('general.buttons.back')" :disabled="upButtonDisabled" />
          </b-col>
          <b-col>

            <DownButton :onClick="nextRow" :buttonText="$t('inventory.next')" />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>

</template>
<script>
import DataService from '@/services/StrapiService.js';
import EditSellingPrice from '../inputs/EditSellingPrice.vue';
import DownButton from '../buttons/DownButton.vue';
import UpButton from '../buttons/UpButton.vue';
import TooltipButton from '../buttons/TooltipButton.vue';
import DuplicateIcon from '../icons/DuplicateIcon.vue';
import DuplicateIconChecked from '../icons/DuplicateIconChecked.vue';


export default {
  name: 'InventorySidepanel',
  props: {
    selectedInventoryVenueWine: {
      type: Object,
      default: null
    },
    selectedRowIndex: {
      type: Number,
    },
    tableLength: {
      type: Number
    },
    showSidepanel: {
      type: Boolean,
      default: false
    },
    currentPage: {
      type: Number
    },
  },
  components: {
    EditSellingPrice,
    DownButton,
    UpButton,
    TooltipButton,
    DuplicateIcon,
    DuplicateIconChecked
  },
  data() {
    return {
      ek: null,
      vk: null,
      shelf: '',
      duplicate: false,
      actualAmount: null,
      actualAmountChanged: false,
      targetAmount: 0,
      numberPad: [[1, 2, 3], [4, 5, 6], [7, 8, 9]],

    }
  },
  computed: {
    upButtonDisabled() {
      return this.selectedRowIndex === 0 && this.currentPage === 1;
    },
  },
  watch: {
    selectedInventoryVenueWine: {
      immediate: true,
      deep: true,
      handler() {
        this.resetInputs();
        if (!this.selectedInventoryVenueWine) {
          return;
        }
        this.vk = this.selectedInventoryVenueWine.sellingPrice
        this.shelf = this.selectedInventoryVenueWine.shelf
        this.ek = this.selectedInventoryVenueWine.purchasePrice
        this.duplicate = this.selectedInventoryVenueWine.duplicate;
        if (this.selectedInventoryVenueWine.actualAmount !== 0 && this.selectedInventoryVenueWine.actualAmount !== null) {
          this.actualAmount = this.selectedInventoryVenueWine.actualAmount
        }
        this.targetAmount = this.selectedInventoryVenueWine.targetAmount
      }
    },
  },
  created() {
  },
  methods: {
    markAsDuplicate() {
      this.duplicate = !this.duplicate;
      var alertobject = {
        text: this.$t("inventory.duplicateMarked"),
        variant: "info"
      };
      this.$store.commit("setAlertText", alertobject);
      this.nextRow();
    },

    nextRow() {
      this.actualAmountChanged = false;
      let inventoryVenueWineState = 'checked'

      /* Sidepanel-Verhalten: wenn nur auf nächstes geklickt: Anzahl 0 setzen und nicht auf checked setzen */
      if (this.actualAmount === null || this.actualAmount == 0) {
        inventoryVenueWineState = 'none'
        this.actualAmount = 0;
      }

      const inventoryVenueWine = {
        sellingPrice: this.vk,
        shelf: this.shelf,
        actualAmount: this.actualAmount,
        targetAmount: Number(this.targetAmount),
        inventoryVenueWineState: inventoryVenueWineState,
        id: this.selectedInventoryVenueWine.id,
        purchasePrice: this.ek,
        duplicate: this.duplicate
      }
      this.saveInventoryVenueWine(inventoryVenueWine);
      if (this.selectedRowIndex === this.tableLength - 1) {
        this.$nextTick(() => {
          this.$emit('nextRow', inventoryVenueWine, 0);
        });
      } else {
        this.$emit('nextRow', inventoryVenueWine);
      }
    },
    previousRow() {
      this.actualAmountChanged = false;
      this.$emit('previousRow');
      this.saveInventoryVenueWine();
    },
    resetInputs() {
      this.ek = null;
      this.vk = null;
      this.shelf = '';
      this.actualAmount = 0;
      this.targetAmount = 0;
    },
    async saveInventoryVenueWine(inventoryVenueWine) {

      DataService.updateInventoryVenueWine(this.selectedInventoryVenueWine.id, inventoryVenueWine).then(() => {
      }).catch((err) => {
        console.log('err :>> ', err);
      })
    },
    addNumber(n) {
      this.actualAmount = this.actualAmount === null ? 0 : this.actualAmount;
      this.actualAmountChanged = true;
      if (this.actualAmount === 0) {
        this.actualAmount = n.toString();
        return;
      }
      this.actualAmount += n.toString();
    },
    deleteNumber() {
      this.actualAmountChanged = true;
      this.actualAmount = this.actualAmount.toString();
      if (this.actualAmount.length === 1) {
        this.actualAmount = null;
        return;
      }
      this.actualAmount = this.actualAmount.slice(0, -1);
    },

  }
}
</script>
<style scoped>
div.sidepanel {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 389px;
  width: 389px;
  top: 0;
  background-color: var(--color-gray-400);
  border-left: 1px solid var(--color-gray-300);
  border-top: 1px solid var(--color-gray-300);
  padding: var(--margin-m);
  flex-direction: column;
  transition: margin 0.8s;
  margin-right: -389px;
  justify-content: space-between;
}

div.sidepanel.active {
  right: 0;
  transform: translateX(0);
  margin-right: 0;
}

div.block-one {
  height: 242px;
}

div.block-two {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
}

div.calculate {
  flex-grow: 1;
}

span.box {
  display: inline;
  padding: 4px 8px;
}

div.tags {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}



button.number {
  display: flex;
  padding: var(--margin-s);
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 52px;
  background-color: var(--color-gray-300);
  border-radius: 6px;
  border: none;
}

.duplicate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 52px;
}

div.calculate {
  flex-direction: column;
}

div.number-row {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px
}

.number-pad {
  width: 187px;
}

.bottom-row {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
}

.bottom-row .delete-btn {
  height: 52px;
  width: 52px;
}

.buttons * {
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.amount-not-set {
  opacity: 0.4;
}
</style>